import {differenceInDays, endOfWeek, format, startOfWeek, startOfYear} from 'date-fns';

export const toLocalDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
}

export const formatDateFromScanner = (dateString: string) => {
    const [day, month, year] = dateString.split('/');
    const fullYear = parseInt(year) < 50 ? '20' + year : '19' + year;
    return new Date(`${month}-${day}-${fullYear}`);
}

export const getDateOfBirth = (date: Date) => {
    return format(date, 'dd.MMM');
}

export const firstDayOfMonth = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    return toLocalDate(firstDayOfMonth);
}

export const firstDayOfYear = () => {
    return toLocalDate(startOfYear(new Date()));
}

export const endDayOfMonth = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
    return toLocalDate(lastDayOfMonth);
}

export const getStartOfWeek = (date: any) => {
    return toLocalDate(startOfWeek(date, {weekStartsOn: 1}));
}

export const getEndOfWeek = (date: any) => {
    return toLocalDate(endOfWeek(date, {weekStartsOn: 1}));
}

export const numberOfDays = (dateCurrent: any, endDate: any) => {
    if (dateCurrent < endDate) {
        return differenceInDays(endDate, dateCurrent);
    } else {
        return 0;
    }
};

export const formatDateTimeToRO = (date: any) => {
    if (date) {
        return `${new Date(date).toLocaleDateString("ro-RO")} | ${new Date(date).toLocaleTimeString("ro-RO")}`
    } else {
        return "-";
    }
};

export const formatDateToRO = (date: any) => {
    if (date) {
        return `${new Date(date).toLocaleDateString("ro-RO")}`
    } else {
        return "-";
    }
};

export function getWeeksInMonth(year: number, month: number) {
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const firstDayOfTheWeek = firstDayOfMonth.getDay();
    const daysInMonth = new Date(year, month, 0).getDate();
    const weeks = [];

    let currentDay = 1;
    let weekStart = 1;
    while (currentDay <= daysInMonth) {
        if (currentDay === daysInMonth || (currentDay + firstDayOfTheWeek) % 7 === 0) {
            const weekEnd = currentDay;
            weeks.push({
                weekNumber: `Week ${weeks.length + 1}`,
                startDate: toLocalDate(new Date(year, month - 1, weekStart)),
                endDate: toLocalDate(new Date(year, month - 1, weekEnd))
            });
            weekStart = currentDay + 1;
        }
        currentDay++;
    }

    return weeks;
}

export function generateYearsArray(minYear: number, maxYear: number) {
    const years = [];
    for (let year = minYear; year <= maxYear; year++) {
        years.push(year);
    }
    return years;
}


